import React from "react"
import {
  Media,
  Text,
  Container,
  useIsLessThanLg,
  SpaceBetween,
} from "@opensea/ui-kit"
import { groupBy } from "lodash"
import { useFragment } from "react-relay"
import { Link } from "@/components/common/Link"
import { StyledGridHScrollContainer } from "@/components/common/StyledGridHScrollContainer"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import {
  CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS,
  Carousel,
} from "@/design-system/Carousel"
import { Grid } from "@/design-system/Grid/Grid.react"
import { useFeaturedCopy } from "@/hooks/useFeaturedCopy"
import { useIsMounted } from "@/hooks/useIsMounted"
import { useTranslate } from "@/hooks/useTranslate"
import { HomePageFeaturedCollections_data$key } from "@/lib/graphql/__generated__/HomePageFeaturedCollections_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { OPEN_SEA_HOST } from "../../../../constants"
import { HomePageCollectionCard } from "../HomePageShelf/HomePageCollectionCard.react"
import { HomePageNotableActivity } from "../HomePageShelf/HomePageNotableActivity.react"

type Props = {
  dataKey: HomePageFeaturedCollections_data$key | null
}

export const HomePageFeaturedCollections = ({ dataKey }: Props) => {
  const data = useFragment(
    graphql`
      fragment HomePageFeaturedCollections_data on HomePageFeaturedSectionCollectionType
      @relay(plural: true) {
        section {
          title
          titleUrl
          relayId
        }
        collection {
          slug
          logo
          banner
          ...HomePageCollectionCard_data @arguments(timeWindow: ALL_TIME)
        }
        overrideUrl
      }
    `,
    dataKey,
  )

  const t = useTranslate("home")
  const isMounted = useIsMounted()
  const titleCopy = useFeaturedCopy()
  const isLessThanLg = useIsLessThanLg()

  if (!data?.length) {
    return null
  }

  // Filter out any collections that don't have a banner or a logo
  const onlyLogoAndBannerCollections = data.filter(
    collection =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      collection && collection.collection.logo && collection.collection.banner,
  )

  // Group by section relayId
  const sections = groupBy(
    onlyLogoAndBannerCollections,
    collection => collection.section.relayId,
  )

  const sectionKeys = Object.keys(sections)

  // We will have maximum three shelves to start with
  const sectionsToDisplay = sectionKeys.slice(0, 3)

  const viewAllButtonText = t("merchShelves.viewAllButton", "View all")

  if (!isMounted) {
    return null
  }

  const featuredShelves = sectionsToDisplay.map((key, sectionIdx) => {
    const featuredCollections = sections[key]
    const { title: dynamicCopy, titleUrl } = sections[key][0].section
    let relativeUrl = titleUrl

    if (relativeUrl?.startsWith("https://")) {
      const host = new URL(relativeUrl).host
      if (host === OPEN_SEA_HOST) {
        relativeUrl = relativeUrl.split(OPEN_SEA_HOST)[1]
      }
    }

    const title = titleCopy[dynamicCopy] || dynamicCopy

    return (
      <Grid.Item key={key}>
        <Media greaterThanOrEqual="lg">
          <SpaceBetween className="mb-6">
            <Text.Heading size="medium">{title}</Text.Heading>
            {relativeUrl && (
              <Link
                eventParams={{
                  sectionIndex: sectionIdx,
                  sectionTitle: title,
                }}
                eventSource="MerchandiseShelf"
                href={relativeUrl}
              >
                <Button
                  size={isLessThanLg ? "small" : "large"}
                  variant="secondary"
                >
                  <Text className="text-primary" size="small" weight="semibold">
                    {viewAllButtonText}
                  </Text>
                </Button>
              </Link>
            )}
          </SpaceBetween>
        </Media>
        <Media lessThan="lg">
          <SpaceBetween className="mb-3">
            <Text.Heading size="small">{title}</Text.Heading>
            {relativeUrl && (
              <Link
                eventParams={{
                  sectionIndex: sectionIdx,
                  sectionTitle: title,
                }}
                eventSource="MerchandiseShelf"
                href={relativeUrl}
              >
                <Button size={isLessThanLg ? "small" : "large"}>
                  {viewAllButtonText}
                </Button>
              </Link>
            )}
          </SpaceBetween>
        </Media>
        <Block marginX={{ _: "-16px", sm: "0px" }}>
          <Media lessThan="sm">
            <Carousel
              centeredSlides
              centeredSlidesBounds
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className="featured_swiper_mobile"
              enableArrowControls={false}
              enableFreeScroll
              enableMousewheel
              id={`hp-featured-swiper-${sectionIdx}-mobile`}
              renderSlide={({ collection }) => collection}
              responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
              showScrollbar={false}
              slides={featuredCollections.map((featuredCollection, idx) => {
                return {
                  id: featuredCollection.collection.slug,
                  collection: (
                    <HomePageCollectionCard
                      collection={featuredCollection.collection}
                      eventParams={{
                        index: idx,
                        sectionIndex: sectionIdx,
                        sectionTitle: featuredCollection.section.title,
                      }}
                      eventSource="MerchandiseShelfCollectionClick"
                      overrideBannerUrl={
                        featuredCollection.overrideUrl ?? undefined
                      }
                      volumeWindow="ALL_TIME"
                    />
                  ),
                }
              })}
              slidesOffsetAfter={16}
              slidesOffsetBefore={16}
              variant="card"
            />
          </Media>
          <Media greaterThanOrEqual="sm">
            <Carousel
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className="featured_swiper"
              enableArrowControls
              enableFreeScroll
              enableMousewheel
              id={`hp-featured-swiper-${sectionIdx}`}
              renderSlide={({ collection }) => collection}
              responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
              showScrollbar={false}
              slides={featuredCollections.map((featuredCollection, idx) => {
                return {
                  id: featuredCollection.collection.slug,
                  collection: (
                    <HomePageCollectionCard
                      collection={featuredCollection.collection}
                      eventParams={{
                        index: idx,
                        sectionIndex: sectionIdx,
                        sectionTitle: featuredCollection.section.title,
                      }}
                      eventSource="MerchandiseShelfCollectionClick"
                      overrideBannerUrl={
                        featuredCollection.overrideUrl ?? undefined
                      }
                      volumeWindow="ALL_TIME"
                    />
                  ),
                }
              })}
              variant="card"
            />
          </Media>
        </Block>
      </Grid.Item>
    )
  })

  const notableActivityShelf = (
    <HomePageNotableActivity key="notableActivityShelf" />
  )
  // Notable activity shelf should always be second if there are other shelves
  featuredShelves.splice(
    featuredShelves.length ? 1 : 0,
    0,
    notableActivityShelf,
  )

  // Overflow hidden used for Carousel "card" variants that require a visible overflow
  return (
    <Container className="overflow-x-hidden">
      <StyledGridHScrollContainer>{featuredShelves}</StyledGridHScrollContainer>
    </Container>
  )
}
