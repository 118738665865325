/**
 * @generated SignedSource<<e87808b1444fa259410082ac5fd2d8d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomePageNotableActivityLazyQuery$variables = {};
export type HomePageNotableActivityLazyQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HomePageNotableActivityShelf_data">;
};
export type HomePageNotableActivityLazyQuery = {
  response: HomePageNotableActivityLazyQuery$data;
  variables: HomePageNotableActivityLazyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalItemCount",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomePageNotableActivityLazyQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "HomePageNotableActivityShelf_data"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomePageNotableActivityLazyQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 24
          }
        ],
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "notableActivityCollections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "banner",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "verificationStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollectionStatsV2Type",
                    "kind": "LinkedField",
                    "name": "statsV2",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalSupply",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "dropv2",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "stages",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endTime",
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mintedItemCount",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "Drop721LimitedEditionType",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "Drop1155LimitedEditionType",
                        "abstractKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "statsTimeWindow",
                        "value": "ONE_DAY"
                      }
                    ],
                    "concreteType": "WindowCollectionStatsType",
                    "kind": "LinkedField",
                    "name": "windowCollectionStats",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriceType",
                        "kind": "LinkedField",
                        "name": "floorPrice",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriceType",
                        "kind": "LinkedField",
                        "name": "volume",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": "windowCollectionStats(statsTimeWindow:\"ONE_DAY\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCategory",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "notableActivityCollections(first:24)"
      }
    ]
  },
  "params": {
    "cacheID": "f088e40c49a6ce5089f2d738446bf7cf",
    "id": null,
    "metadata": {},
    "name": "HomePageNotableActivityLazyQuery",
    "operationKind": "query",
    "text": "query HomePageNotableActivityLazyQuery {\n  ...HomePageNotableActivityShelf_data\n}\n\nfragment HomePageCollectionCardFooter_data_3C7EJl on CollectionType {\n  windowCollectionStats(statsTimeWindow: ONE_DAY) {\n    floorPrice {\n      unit\n      symbol\n    }\n    volume {\n      unit\n      symbol\n    }\n  }\n}\n\nfragment HomePageCollectionCard_data_3C7EJl on CollectionType {\n  banner\n  name\n  verificationStatus\n  ...useDropState_data\n  ...HomePageCollectionCardFooter_data_3C7EJl\n  ...collection_url\n}\n\nfragment HomePageNotableActivityShelf_data on Query {\n  notableActivityCollections(first: 24) {\n    edges {\n      node {\n        slug\n        banner\n        ...HomePageCollectionCard_data_3C7EJl\n        id\n      }\n    }\n  }\n}\n\nfragment collection_url on CollectionType {\n  slug\n  isCategory\n}\n\nfragment useDropState_data on CollectionType {\n  statsV2 {\n    totalSupply\n  }\n  dropv2 {\n    __typename\n    stages {\n      __typename\n      startTime\n      endTime\n      id\n    }\n    mintedItemCount\n    ... on Drop721LimitedEditionType {\n      totalItemCount\n    }\n    ... on Drop1155LimitedEditionType {\n      totalItemCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f5a7da123aead4de973003a8b23ffc18";

export default node;
