import React from "react"
import { Text } from "@opensea/ui-kit"
import { Link } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { Modal } from "@/design-system/Modal"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { IsIpRightsTakedownDelistedMode } from "../../constants"

type IpRightsDelistedNoticeProps = {
  IpRightsTakedownDelistedMode: IsIpRightsTakedownDelistedMode
}

export const IpRightsDelistedNoticeModal = ({
  IpRightsTakedownDelistedMode,
}: IpRightsDelistedNoticeProps) => {
  const t = useTranslate("modals")
  const router = useRouter()
  return (
    <>
      <Modal isOpen>
        <Modal.Header>
          <Modal.Header.Title>
            {t(
              "ipRightsDelistedNotice.contentNoLongerAccessible.title",
              "This content is no longer accessible on OpenSea.",
            )}
          </Modal.Header.Title>
        </Modal.Header>

        <Modal.Body>
          <Block>
            <Text asChild>
              <p>
                {t(
                  "ipRightsDelistedNotice.contentNoLongerAccessible.description",
                  `This content is no longer accessible on OpenSea. It has been
              removed based on a claim of intellectual property infringement.
              Learn more about how OpenSea handles these claims `,
                )}
                <Link href="https://support.opensea.io/en/articles/8867068-what-can-i-do-if-my-copyrighted-works-are-being-sold-without-my-permission">
                  {t("common.here", "here")}
                </Link>
                .
              </p>
            </Text>
            {IpRightsTakedownDelistedMode == "item-owner" && (
              <Text asChild>
                <p>
                  {t(
                    "ipRightsDelistedNotice.activeListing.description",
                    `If this item has an active listing, you can remove it `,
                  )}
                  <Link href="account/settings?tab=support">
                    {t("common.here", "here")}
                  </Link>
                  .
                </p>
              </Text>
            )}
            {IpRightsTakedownDelistedMode == "collection-owner" && (
              <Text asChild>
                <p>
                  {t(
                    "ipRightsDelistedNotice.activeCollectionListing.link",
                    `If this collection has active listings, you can remove them`,
                  )}{" "}
                  <Link href="account/settings?tab=support">
                    {t("common.here", "here")}
                  </Link>
                  .
                </p>
              </Text>
            )}
          </Block>
        </Modal.Body>

        <Modal.Footer>
          <Modal.Footer.Button
            variant="secondary"
            onClick={() => router.push("/")}
          >
            {t("common.close", "Close")}
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
