import React from "react"
import { FlexColumn } from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { map } from "lodash"
import { graphql } from "react-relay"
import { createGlobalStyle } from "styled-components"
import { StyledGridHScrollContainer } from "@/components/common/StyledGridHScrollContainer"
import { BrowseCategories } from "@/components/layout/home-page/BrowseCategories.react"
import { BrowseCategoriesError } from "@/components/layout/home-page/BrowseCategoriesError.react"
import { CampaignAnnouncementModal } from "@/components/modals/CampaignAnnouncementModal.react"
import { DelistedNoticeModal } from "@/components/modals/DelistedNoticeModal.react"
import { IpRightsDelistedNoticeModal } from "@/components/modals/IpRightsDelistedNoticeModal.react"
import { OpenSeaPage } from "@/containers/OpenSeaPage.react"
import { Grid } from "@/design-system/Grid/Grid.react"
import { Modal } from "@/design-system/Modal"
import { useAnnouncementBanner } from "@/features/announcement-banner/utils/context"
import { TrendingCategoryListContainer } from "@/features/categorization/TrendingCategory/TrendingCategoryListContainer.react"
import { HomePageFeaturedCollections } from "@/features/home/components/HomePageFeatured/HomePageFeaturedCollections.react"
import { CategoryLinks } from "@/features/home/components/HomePageHeader/CategoryLinks.react"
import { HomePageHeader } from "@/features/home/components/HomePageHeader/HomePageHeader.react"
import { HomePageStatsTables } from "@/features/home/components/HomePageStats/HomePageStatsTables.react"
import { HomePageLearnCenterCarousel } from "@/features/home/components/LearnCenter/HomePageLearnCenterCarousel.react"
import { PrimaryDropSpotlight } from "@/features/primary-drops/components/PrimaryDropSpotlight"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useRouter } from "@/hooks/useRouter"
import { trackHomePage } from "@/lib/analytics/events/pageEvents"
import {
  HomePageQuery,
  HomePageQuery$data,
} from "@/lib/graphql/__generated__/HomePageQuery.graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import { captureNoncriticalError } from "@/lib/sentry"

export const homePageQuery = graphql`
  query HomePageQuery {
    homePageFeaturedSectionCollections {
      ...HomePageFeaturedCollections_data
    }
    homePage {
      heroSpotlights {
        dropSpotlight {
          ...PrimaryDropSpotlight_data
          __typename
          dropv2 {
            __typename
          }
        }
      }
    }
    ...HomePageHeader_data
    ...TrendingCategoryListContainer_data
    ...CampaignAnnouncementModal_data
  }
`

type GetNullable<T, Prop extends keyof NonNullable<T>> = NonNullable<T>[Prop]

export const HomePage: GraphQLNextPage<HomePageQuery> = ({ data }) => {
  const router = useRouter()
  const { spotlightTopOffset } = useAnnouncementBanner()

  useMountEffect(() => {
    trackHomePage()
  })

  const showDelistedNotice = QP.parse({
    show_delisted_notice: QP.Optional(QP.boolean),
  }).show_delisted_notice

  const IpRightsTakedownDelistedMode = QP.parse({
    show_ip_rights_delisted_notice: QP.Optional(QP.isIpRightsTakedownDelisted),
  }).show_ip_rights_delisted_notice

  type DropSpotlight = NonNullable<
    GetNullable<
      GetNullable<
        GetNullable<HomePageQuery$data["homePage"], "heroSpotlights">,
        number
      >,
      "dropSpotlight"
    >
  >
  const dropSpotlights = map(
    data?.homePage?.heroSpotlights,
    heroSpotlight => heroSpotlight?.dropSpotlight,
  ).filter(
    dropSpotlight => dropSpotlight?.dropv2?.__typename,
  ) as DropSpotlight[]
  const dropSpotlight = dropSpotlights[0] as DropSpotlight | undefined

  return (
    <>
      <GlobalSmoothScrollBehaviour />
      <OpenSeaPage
        announcementBannerProps={{
          displayContext: "HOMEPAGE_ONLY",
          allowSticky: true,
        }}
        hasTransparentNavbar
      >
        <StyledGridHScrollContainer marginBottom={40}>
          {dropSpotlight && data?.homePage ? (
            <Grid.Item xs={12}>
              <CategoryLinks color="white" isPrimaryDropActive />
              <PrimaryDropSpotlight
                collectionKey={dropSpotlights}
                eventSource="PrimaryDropSpotlight"
              />
            </Grid.Item>
          ) : (
            <Grid.Item xs={12}>
              <FlexColumn
                className="relative mb-6 w-full overflow-hidden xs:px-4 sm:px-8 xxl:px-16"
                style={{
                  marginTop: -spotlightTopOffset,
                  paddingTop: spotlightTopOffset,
                }}
              >
                <HomePageHeader dataKey={data ?? null} showCategoryLinks />
              </FlexColumn>
            </Grid.Item>
          )}

          <Grid.Item xs={12}>
            <HomePageStatsTables />
          </Grid.Item>

          <Grid.Item xs={12}>
            <HomePageFeaturedCollections
              dataKey={data?.homePageFeaturedSectionCollections ?? null}
            />
          </Grid.Item>

          <Grid.Item xs={12}>
            <TrendingCategoryListContainer dataKey={data ?? null} />
          </Grid.Item>

          <Grid.Item xs={12}>
            <HomePageLearnCenterCarousel />
          </Grid.Item>

          <Grid.Item xs={12}>
            <ErrorBoundary
              fallback={({ error }) => {
                captureNoncriticalError(error)
                return <BrowseCategoriesError />
              }}
            >
              <BrowseCategories />
            </ErrorBoundary>
          </Grid.Item>
        </StyledGridHScrollContainer>
        {showDelistedNotice && (
          <Modal isOpen>
            <DelistedNoticeModal
              variant="home"
              onClose={() => router.push("/")}
            />
          </Modal>
        )}

        <CampaignAnnouncementModal dataKey={data ?? null} />

        {IpRightsTakedownDelistedMode && (
          <IpRightsDelistedNoticeModal
            IpRightsTakedownDelistedMode={IpRightsTakedownDelistedMode}
          />
        )}
      </OpenSeaPage>
    </>
  )
}

export const GlobalSmoothScrollBehaviour = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
`
