import { useTranslate } from "@/hooks/useTranslate"

export const useFeaturedCopy = () => {
  const t = useTranslate("home")

  // The extraction cool does not work with variables, so we need to repeat the copy this way
  const copies: Record<string, string> = {
    "New and notable": t("constants.newAndNotable.heading", "New and notable"),
    "Sports spotlight": t("constants.Sports.heading", "Sports spotlight"),
    "Photography spotlight": t(
      "constants.photography.heading",
      "Photography spotlight",
    ),
    "Music spotlight": t("constants.Music.heading", "Music spotlight"),
    "Domain names spotlight": t(
      "constants.domains.heading",
      "Domain names spotlight",
    ),
    "Ticketing spotlight": t(
      "constants.ticketing.heading",
      "Ticketing spotlight",
    ),
    "Gaming spotlight": t("constants.Gaming.heading", "Gaming spotlight"),
    "Virtual worlds spotlight": t(
      "constants.virtualWorlds.heading",
      "Virtual worlds spotlight",
    ),
    "Trading cards spotlight": t(
      "constants.tradingCards.heading",
      "Trading cards spotlight",
    ),
    "Utility spotlight": t("constants.Utility.heading", "Utility spotlight"),
    "Membership spotlight": t(
      "constants.membership.heading",
      "Membership spotlight",
    ),
    "Collectibles spotlight": t(
      "constants.collectibles.heading",
      "Collectibles spotlight",
    ),
    "Amber Vittoria spotlight": t(
      "constants.amberVittoria.heading",
      "Amber Vittoria spotlight",
    ),
    "Klaytn spotlight": t("constants.klaytn.heading", "Klaytn spotlight"),
    "Solana spotlight": t("constants.solana.heading", "Solana spotlight"),
    "Black History Month spotlight": t(
      "constants.blackHistoryMonth.heading",
      "Black History Month spotlight",
    ),
    "Women's History Month spotlight": t(
      "constants.womenHistoryMonth.heading",
      "Women's History Month spotlight",
    ),
    "Pride Month spotlight": t(
      "constants.prideMonth.heading",
      "Pride Month spotlight",
    ),
    "AAPI Heritage Month spotlight": t(
      "constants.AAPIHeritageMonth.heading",
      "AAPI Heritage Month spotlight",
    ),
    "Latinx Heritage Month spotlight": t(
      "constants.latinxHeritageMonth.heading",
      "Latinx Heritage Month spotlight",
    ),
    "Latin Heritage Month spotlight": t(
      "constants.latinHeritageMonth.heading",
      "Latin Heritage Month spotlight",
    ),
    "Native American Heritage Month spotlight": t(
      "constants.nativeAmericanHeritageMonth.heading",
      "Native American Heritage Month spotlight",
    ),
    "Happy Halloween!": t(
      "constants.happyHalloween.heading",
      "Happy Halloween!",
    ),
  }

  return copies
}
