import React, { useState } from "react"
import { Text, Media } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { Flex } from "@/design-system/Flex"
import { Grid } from "@/design-system/Grid/Grid.react"
import { nonInteractiveOverlay } from "@/features/primary-drops/styleUtils"
import { useTranslate } from "@/hooks/useTranslate"
import { HomePageHeader_data$key } from "@/lib/graphql/__generated__/HomePageHeader_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { CategoryLinks } from "./CategoryLinks.react"
import { HomePageHeaderCarousel } from "./HomePageHeaderCarousel.react"

type Props = {
  dataKey: HomePageHeader_data$key | null
  showCategoryLinks?: boolean
}

export const HomePageHeader = ({
  dataKey,
  showCategoryLinks = false,
}: Props) => {
  const data = useFragment(
    graphql`
      fragment HomePageHeader_data on Query {
        homePageHeaderCarousel {
          collection {
            logo
          }
          overrideUrl
          ...HomePageHeaderCarousel_data
        }
      }
    `,
    dataKey,
  )
  const [blurredBackgroundImg, setBlurredBackgroundImg] = useState<string>(
    data?.homePageHeaderCarousel[0]?.overrideUrl ??
      data?.homePageHeaderCarousel[0]?.collection.logo ??
      "",
  )

  const t = useTranslate("home")
  const headerText = t("header.text", "Explore, collect, and sell NFTs")
  return (
    <>
      <BackgroundImage $imgUrl={blurredBackgroundImg} />
      {showCategoryLinks ? (
        <Grid>
          <Grid.Item xs={12}>
            <CategoryLinks color="white" />
          </Grid.Item>
        </Grid>
      ) : (
        <>
          <Media greaterThanOrEqual="xxl">
            <Grid>
              <Grid.Item lg={12} marginBottom="56px" marginTop="40px">
                <Flex className="text-center" justifyContent="center">
                  <Text.Display
                    asChild
                    data-testid="Home--headerText"
                    size="large"
                  >
                    <h1>{headerText}</h1>
                  </Text.Display>
                </Flex>
              </Grid.Item>
            </Grid>
          </Media>

          <Media between={["sm", "xxl"]}>
            <Grid>
              <Grid.Item marginBottom="44px" marginTop="32px" xs={12}>
                <Media greaterThanOrEqual="sm">
                  <Flex className="text-center" justifyContent="center">
                    <Media between={["xl", "xxl"]}>
                      <Text.Heading
                        asChild
                        data-testid="Home--headerText"
                        size="large"
                      >
                        <h1>{headerText}</h1>
                      </Text.Heading>
                    </Media>

                    <Media between={["sm", "xl"]}>
                      <Text.Display
                        asChild
                        data-testid="Home--headerText"
                        size="large"
                      >
                        <h1>{headerText}</h1>
                      </Text.Display>
                    </Media>
                  </Flex>
                </Media>
              </Grid.Item>
            </Grid>
          </Media>

          <Media lessThan="sm">
            <Grid margin="32px 24px 44px 24px">
              <Grid.Item xs={12}>
                <Flex className="text-center" justifyContent="center">
                  <Text.Display
                    asChild
                    data-testid="Home--headerText"
                    size="medium"
                  >
                    <h1>{headerText}</h1>
                  </Text.Display>
                </Flex>
              </Grid.Item>
            </Grid>
          </Media>
        </>
      )}
      <Grid>
        <Grid.Item xs={12}>
          <HomePageHeaderCarousel
            dataKey={data?.homePageHeaderCarousel ?? null}
            handleSlideIndexChange={(imgSrc: string) =>
              setBlurredBackgroundImg(imgSrc)
            }
          />
        </Grid.Item>
      </Grid>
    </>
  )
}

const BackgroundImage = styled(Flex)<{
  $imgUrl?: string
}>`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  right: 0;
  background-image: ${props => `url(${props.$imgUrl})`};
  background-size: cover;
  transition: background 0.3s linear;

  &:after {
    backdrop-filter: blur(60px);
    background: linear-gradient(
      0deg,
      ${props => props.theme.colors.base1} 5%,
      rgba(0, 0, 0, 0) 60%
    );
    background-color: rgba(0, 0, 0, 0.5);
    ${nonInteractiveOverlay}
  }
`
