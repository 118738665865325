/**
 * @generated SignedSource<<55aa045e2c4260c56bb8e23bbadc6c62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomePageFeaturedCollections_data$data = ReadonlyArray<{
  readonly collection: {
    readonly banner: string | null;
    readonly logo: string | null;
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"HomePageCollectionCard_data">;
  };
  readonly overrideUrl: string | null;
  readonly section: {
    readonly relayId: string;
    readonly title: string;
    readonly titleUrl: string | null;
  };
  readonly " $fragmentType": "HomePageFeaturedCollections_data";
}>;
export type HomePageFeaturedCollections_data$key = ReadonlyArray<{
  readonly " $data"?: HomePageFeaturedCollections_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomePageFeaturedCollections_data">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "HomePageFeaturedCollections_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HomePageFeaturedSectionType",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "titleUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "relayId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "banner",
          "storageKey": null
        },
        {
          "args": [
            {
              "kind": "Literal",
              "name": "timeWindow",
              "value": "ALL_TIME"
            }
          ],
          "kind": "FragmentSpread",
          "name": "HomePageCollectionCard_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overrideUrl",
      "storageKey": null
    }
  ],
  "type": "HomePageFeaturedSectionCollectionType",
  "abstractKey": null
};

(node as any).hash = "92d629818293706f71bc17a8fbf8608a";

export default node;
