import React from "react"
import { graphql, useFragment } from "react-relay"
import { TrendingCategoryListContainer_data$key } from "@/lib/graphql/__generated__/TrendingCategoryListContainer_data.graphql"
import { CATEGORY_SLUGS } from "../../../constants"
import { TrendingCategoryList } from "./TrendingCategoryList.react"

type Props = {
  dataKey: TrendingCategoryListContainer_data$key | null
}

export const TrendingCategoryListContainer = ({ dataKey }: Props) => {
  const categories = useFragment(
    graphql`
      fragment TrendingCategoryListContainer_data on Query {
        categoriesV2 {
          slug
          # eslint-disable-next-line relay/unused-fields
          name
        }
      }
    `,
    dataKey,
  )
  if (!categories) {
    return null
  }
  const filterCategories = categories.categoriesV2.filter(category =>
    CATEGORY_SLUGS.includes(category.slug),
  )

  return <TrendingCategoryList categories={filterCategories} />
}
