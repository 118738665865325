/**
 * @generated SignedSource<<288830dda27bacf806eef4755a50f582>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomePageHeader_data$data = {
  readonly homePageHeaderCarousel: ReadonlyArray<{
    readonly collection: {
      readonly logo: string | null;
    };
    readonly overrideUrl: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"HomePageHeaderCarousel_data">;
  }>;
  readonly " $fragmentType": "HomePageHeader_data";
};
export type HomePageHeader_data$key = {
  readonly " $data"?: HomePageHeader_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomePageHeader_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomePageHeader_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HomePageHeaderCarouselType",
      "kind": "LinkedField",
      "name": "homePageHeaderCarousel",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logo",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overrideUrl",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomePageHeaderCarousel_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "083b6db7d49b923a1cda263b8f2b4822";

export default node;
