import React, { Suspense } from "react"
import { Media, SpaceBetween, Text } from "@opensea/ui-kit"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { IS_SERVER } from "@/constants/environment"
import { Block } from "@/design-system/Block"
import {
  CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS,
  Carousel,
} from "@/design-system/Carousel"
import { Grid } from "@/design-system/Grid/Grid.react"
import { useIsMounted } from "@/hooks/useIsMounted"
import { useTranslate } from "@/hooks/useTranslate"
import { HomePageNotableActivityLazyQuery } from "@/lib/graphql/__generated__/HomePageNotableActivityLazyQuery.graphql"
import { HomePageNotableActivityShelf_data$key } from "@/lib/graphql/__generated__/HomePageNotableActivityShelf_data.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { HomePageCollectionCard } from "./HomePageCollectionCard.react"

type Props = {
  dataKey: HomePageNotableActivityShelf_data$key | null
}

export const HomePageNotableActivity = () => {
  const dataKey = useLazyLoadQuery<HomePageNotableActivityLazyQuery>(
    graphql`
      query HomePageNotableActivityLazyQuery {
        ...HomePageNotableActivityShelf_data
      }
    `,
    {},
  )

  return IS_SERVER ? (
    <></>
  ) : (
    <Suspense fallback={<></>}>
      <HomePageNotableActivityShelf dataKey={dataKey} />
    </Suspense>
  )
}

const HomePageNotableActivityShelf = ({ dataKey }: Props) => {
  const data = useFragment(
    graphql`
      fragment HomePageNotableActivityShelf_data on Query {
        notableActivityCollections(first: 24) {
          edges {
            node {
              slug
              banner
              ...HomePageCollectionCard_data @arguments(timeWindow: ONE_DAY)
            }
          }
        }
      }
    `,
    dataKey,
  )

  const t = useTranslate("home")
  const collections = getNodes(data?.notableActivityCollections).filter(
    collection => !!collection.banner,
  )

  const isMounted = useIsMounted()

  if (!data || collections.length < 6 || !isMounted) {
    return null
  }

  const title = t("notableActivity.title", "Top Collector Buys Today")

  return (
    <Grid.Item>
      <Media greaterThanOrEqual="lg">
        <SpaceBetween className="mb-6 items-center">
          <Text.Heading size="medium">{title}</Text.Heading>
        </SpaceBetween>
      </Media>
      <Media lessThan="lg">
        <SpaceBetween className="mb-4 items-center sm:mb-6">
          <Text.Heading size="small">{title}</Text.Heading>
        </SpaceBetween>
      </Media>
      <Block marginX={{ _: "-16px", sm: "0px" }}>
        <Media lessThan="sm">
          <Carousel
            centeredSlides
            centeredSlidesBounds
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="algorithmic_shleves_swiper_mobile"
            enableArrowControls={false}
            enableFreeScroll
            enableMousewheel
            id="hp-algorithmic-shelves-swiper-mobile"
            renderSlide={({ collection }) => collection}
            responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
            showScrollbar={false}
            slides={collections.map((collection, index) => {
              return {
                id: collection.slug,
                collection: (
                  <HomePageCollectionCard
                    collection={collection}
                    eventParams={{
                      index,
                      sectionIndex: 1,
                      sectionTitle: "Top Collector Buys Today",
                    }}
                    eventSource="AlgorithmicShelfCollectionClick"
                    volumeWindow="ONE_DAY"
                  />
                ),
              }
            })}
            slidesOffsetAfter={16}
            slidesOffsetBefore={16}
            variant="card"
          />
        </Media>
        <Media greaterThanOrEqual="sm">
          <Carousel
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="algorithmic_shleves_swiper"
            enableArrowControls
            enableFreeScroll
            enableMousewheel
            id="hp-algorithmic-shelves-swiper"
            renderSlide={({ collection }) => collection}
            responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
            showScrollbar={false}
            slides={collections.map((collection, index) => {
              return {
                id: collection.slug,
                collection: (
                  <HomePageCollectionCard
                    collection={collection}
                    eventParams={{
                      index,
                      sectionIndex: 1,
                      sectionTitle: "Top Collector Buys Today",
                    }}
                    eventSource="AlgorithmicShelfCollectionClick"
                    volumeWindow="ONE_DAY"
                  />
                ),
              }
            })}
            variant="card"
          />
        </Media>
      </Block>
    </Grid.Item>
  )
}
