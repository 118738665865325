/**
 * @generated SignedSource<<078b2ce3228dd07823edd91e95f067e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomePageNotableActivityShelf_data$data = {
  readonly notableActivityCollections: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly banner: string | null;
        readonly slug: string;
        readonly " $fragmentSpreads": FragmentRefs<"HomePageCollectionCard_data">;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "HomePageNotableActivityShelf_data";
};
export type HomePageNotableActivityShelf_data$key = {
  readonly " $data"?: HomePageNotableActivityShelf_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomePageNotableActivityShelf_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomePageNotableActivityShelf_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 24
        }
      ],
      "concreteType": "CollectionTypeConnection",
      "kind": "LinkedField",
      "name": "notableActivityCollections",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CollectionType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "banner",
                  "storageKey": null
                },
                {
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "timeWindow",
                      "value": "ONE_DAY"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "HomePageCollectionCard_data"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "notableActivityCollections(first:24)"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "d135378c4ecc7b05eaf3354ed0c84a8f";

export default node;
